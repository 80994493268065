'use client';
import styled, { css } from 'styled-components';
import { colors, toRgba, darken } from '@/styles';

const buttonHoverShadow = css`
  box-shadow: 0 4px 7px ${toRgba(colors.primary.gray, 0.05)};
  transition: box-shadow 0.1s ease-in-out;
`;

const primaryButtonHoverShadow = css`
  box-shadow: 0 4px 7px ${toRgba(darken('#6e40c9', 0.5), 0.25)};
  transition: box-shadow 0.1s ease-in-out;
`;

const primaryGradient = `
  linear-gradient(120deg, #6e40c9 0%, ${darken('#6e40c9', 0.25)} 100%), #6e40c9;
`;

export const Button = styled.button<{
  $nowrap?: boolean;
  $stretch?: boolean;
  $rounded?: boolean;
  $small?: boolean;
  $inline?: boolean;
}>`
  display: ${(props) => (props.$stretch ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: ${(props) => (props.$stretch ? '100%' : 'auto')};
  height: ${(props) => (props.$inline ? '38px' : 'auto')};
  min-height: ${(props) => (props.$inline ? '38px' : 'auto')};
  border: 1px solid transparent;
  border-radius: ${(props) => (props.$rounded ? '500px' : '5px')};
  white-space: ${(props) => (props.$nowrap ? 'nowrap' : 'inherit')};
  cursor: pointer;

  color: ${colors.primary.white};

  background: ${colors.primary.gray};

  /* font-size: ${({ $small }) => ($small ? '0.95em' : '1.15em')}; */
  font-size: ${({ $small }) => ($small ? '14px' : '16px')};
  padding: ${({ $small, $inline }) => ($small ? '10px 20px' : $inline ? '0 10px' : '15px 30px')};

  &:disabled {
    cursor: default;
    filter: grayscale(60%);
    opacity: 0.5;
    &:hover {
      box-shadow: none;
    }
  }

  &:hover {
    ${buttonHoverShadow}
  }
`;

export const PrimaryButton = styled(Button)`
  color: white;
  background: ${primaryGradient};
`;

export const PrimaryOutlineButton = styled(Button)`
  position: relative;
  color: #6e40c9;
  z-index: 1;
  background: ${primaryGradient};
  &::before {
    content: '';
    border-radius: 4px;
    position: absolute;
    inset: 1px;
    z-index: -1;
    background-color: white;
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${colors.primary.gray};
  background: transparent;
  border-color: ${colors.secondary.lightGray2};
  border-width: 1px;
  &:hover {
    box-shadow: none;
  }
  &:disabled {
    color: ${colors.semantic.disabled};
    background: transparent;
    border-color: ${colors.secondary.lightGray2};
  }
  &.danger {
    color: ${colors.semantic.negative.dark};
    background: transparent;
    border-color: ${colors.semantic.negative.dark};
  }
  &.success {
    color: ${colors.semantic.positive.dark};
    background: transparent;
    border-color: ${colors.semantic.positive.dark};
  }
`;

export const BasicButton = styled(Button)`
  color: ${colors.primary.gray};
  background: ${colors.secondary.lightGray2};
  border-color: ${colors.secondary.lightGray3};
  border-width: 1px;

  &:disabled {
    color: ${colors.semantic.disabled};
    background: ${colors.secondary.lightGray1};
    border-color: ${colors.secondary.lightGray1};
  }
  &.danger {
    color: ${colors.primary.white};
    background: ${colors.semantic.negative.dark};
    border-color: ${colors.semantic.negative.dark};
  }
  &.success {
    color: ${colors.primary.white};
    background: ${colors.semantic.positive.primary};
    border-color: ${colors.semantic.positive.primary};
  }
`;

export const TextButton = styled(Button)`
  color: ${colors.primary.gray};
  background: transparent;
  border-color: transparent;
  padding: 0;
  &:hover {
    box-shadow: none;
    color: ${colors.semantic.link};
  }
  &:disabled {
    color: ${colors.semantic.disabled};
    background: transparent;
    border-color: transparent;
  }
`;

export const CtaButton = styled(Button)`
  color: ${colors.primary.white};
  background: ${colors.secondary.blue2};
  border-color: ${colors.secondary.blue2};
  border-width: 1px;

  &:disabled {
    color: ${colors.primary.black};
    background: ${colors.secondary.blue1};
    border-color: ${colors.secondary.blue1};
  }

  &.alternate {
    color: ${colors.primary.white};
    background: ${colors.secondary.blue3};
    border-color: ${colors.secondary.blue3};
  }

  &.selected {
    color: ${colors.primary.black};
    background: ${colors.secondary.blue1};
    border-color: ${colors.secondary.blue1};
  }
`;

export const MagicButton = styled(Button)`
  color: #6e40c9;
  background: ${colors.primary.white};
  border-color: transparent;

  /* padding: 0; */
  /* &:hover {
    box-shadow: none;
  } */
  &:disabled {
    color: ${colors.semantic.disabled};
    background: transparent;
    border-color: transparent;
  }
`;

export const MagicButtonWrapper = styled.div<{ $glow?: boolean; $borderWidth?: number }>`
  --border-angle: 0turn;

  display: inline-block;

  /* height: 100%; */
  position: relative;
  width: auto;

  & > * {
    position: relative;
    border: none;
  }

  &:not(.on) {
    /* z-index: 1; */
  }

  & {
    border-radius: ${({ $borderWidth }) => ($borderWidth || 2) / 2 + 5}px;
    z-index: 0;

    & > button {
      border-radius: 5px;
      border: none;
      border-color: lime;
    }

    --clr-1: #f87a7a;
    --clr-2: #f9947d;
    --clr-3: #f57ab1;
    --clr-4: #e07de8;
    --clr-5: #dbb2ed;
    --clr-6: #efb4c9;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: -${({ $borderWidth }) => $borderWidth || 2}px;
      z-index: -1;

      background: conic-gradient(
        from var(--border-angle),
        var(--clr-1),
        var(--clr-2),
        var(--clr-3),
        var(--clr-4),
        var(--clr-5),
        var(--clr-6),
        var(--clr-1)
      );
      border-radius: inherit;
      background-position: center center;
      background-repeat: no-repeat;
      animation: bg-spin 2s linear infinite;
    }

    &:after {
      filter: ${(props) => (props.$glow ? 'blur(0.5rem)' : 'none')};
      opacity: 0.75;
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
    &:hover {
      animation-play-state: paused;
      &:after {
        /* filter: ${(props) => (props.$glow ? 'blur(1rem)' : 'none')}; */
        opacity: 1;
      }
    }
    @property --border-angle {
      syntax: '<angle>';
      inherits: true;
      initial-value: 0turn;
    }
  }
`;
