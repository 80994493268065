import React from 'react';

import * as Styled from './styled';

export type TagVariant = 'white' | 'info' | 'error' | 'success' | 'warning' | 'default' | 'subtle' | 'transparent';

interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  variant?: TagVariant;
  counter?: number;
  border?: boolean;
  pill?: boolean;
  small?: boolean;
  className?: string;
}

const Tag: React.FC<TagProps> = ({
  children = '...',
  variant = 'default',
  counter,
  pill,
  small,
  className,
  border,
  ...rest
}) => {
  return (
    <Styled.Tag className={className} $pill={pill} $small={small} $variant={variant} $withBorder={border} {...rest}>
      {children}
      {!!counter && <Styled.Counter>{counter}</Styled.Counter>}
    </Styled.Tag>
  );
};

export default Tag;
