import { IPagination, TKeyValuePair } from '@/types';
import axios, { AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import Cookies from 'js-cookie';
import { BASE_API_URL } from '@/config/environment';
import * as Sentry from '@sentry/nextjs';

interface IErrorParam {
  key: string;
  messages: string[];
}
export interface IResponseError {
  type: string;
  title: string;
  detail?: string;
  params?: IErrorParam[];
}

type TStatus = 'pending' | 'success' | 'error';

export interface IBaseResponse {
  status: TStatus;
  oxen_version?: string | null;
  status_message: string;
  statusCode?: number;
  statusText?: string;
  isLoading?: boolean;
  error?: IResponseError | null;
}

const FIVE_SECONDS = 1000 * 5;
const ONE_MINUTE = 1000 * 60;

export const extractPaginationInfo = (data: any): IPagination => {
  const { page_number, page_size, total_entries, total_pages } = data;
  return {
    page_number: page_number || null,
    page_size: page_size || null,
    total_entries: total_entries || null,
    total_pages: total_pages || null,
  };
};

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: ONE_MINUTE,
});

export const getUserIdFromCookie = () => {
  const token = Cookies.get('oxen_user_id');
  return token;
};

export const getUserNameFromCookie = () => {
  return Cookies.get('oxen_username');
};

export const getTokenFromCookie = () => {
  const token = Cookies.get('oxen_user_token');
  return token;
};

export const getRequestHeaders = (token: string | undefined): RawAxiosRequestHeaders => {
  return { Authorization: `Bearer ${token || getTokenFromCookie()}` };
};

const fetchErrorContext = (targetUrl: string, res: Response, error: any) => {
  return {
    targetUrl: targetUrl,
    detail: error?.detail,
    params: error?.params?.toString(),
    errorTitle: error?.title,
    errorType: error?.type,
    status: res.status,
  };
};

export const fetchWrapper = async ({
  resourceName,
  targetUrl,
  method = 'GET',
  contentType,
  resourceContext,
  authToken,
  body,
  next,
  extra,
}: {
  resourceName: string;
  targetUrl: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  contentType?: string;
  // requestHeaders?: HeadersInit;
  resourceContext?: TKeyValuePair;
  authToken?: string;
  body?: any;
  next?: NextFetchRequestConfig | undefined;
  extra?: TKeyValuePair | undefined;
}): Promise<any> => {
  const bearerToken = authToken || getTokenFromCookie();
  const requestHeaders = (
    !!contentType
      ? ({ Authorization: `Bearer ${bearerToken}`, 'Content-Type': contentType.toString() } as HeadersInit)
      : { Authorization: `Bearer ${bearerToken}` }
  ) as HeadersInit;

  const response = await fetch(targetUrl, { method: method, headers: requestHeaders, body: body, next: next });
  const data = await response.json();
  if (response.ok) {
    return data;
  } else {
    const { error } = data;
    Sentry.withScope(function (scope) {
      if (resourceContext) scope.setContext('Oxen Resource', resourceContext);
      scope.setContext('Fetch Error', fetchErrorContext(targetUrl, response, error));
      Sentry.captureException(new Error(`Failed to fetch ${resourceName}`), { extra: extra });
    });
    return data;
  }
};
